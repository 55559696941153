.card_wrapper {
  border: none;
}

.card_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 6rem;
  overflow: auto;
}

.blocks_divider {
  height: 100%;
  color: var(--grey200-color);
}

.button {
  color: var(--primary-color);
  margin: 1rem 0;
  float: right;
}
