.login_logo {
  margin: 2rem 0;
}

.login_title {
  padding: 1rem 0;
  border-bottom: 0.2rem solid var(--grey700-color);
  max-width: 30%;
  margin-bottom: 1.5rem;
}

.forget_title {
  padding: 1rem 0;
  border-bottom: 0.2rem solid var(--grey700-color);
  max-width: 50%;
  margin-bottom: 1.5rem;
}
