.group_tag {
  background: var(--blue100-color);
  padding: 5px 6px;
  border-radius: 6px;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  font-size: 12px;
}

.group_tag:hover {
  border-color: var(--primary-color);
}

.group_tag_label {
  color: var(--primary-color);
}
