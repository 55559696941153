.pcup_container {
  margin: 5rem 0;
}

.pcup_heading {
  font-size: 2rem;
}

.pcup_wrapper {
  display: flex;
  align-items: flex-start;
}

.pcup_card_wrapper {
  position: relative;
  margin: 2rem 2rem 0 0;
  width: calc((1430px / 3));
}

.pcup_card_wrapper:last-child {
  margin-right: 0;
}

.pcup_card_wrapper,
.pcup_card_suffix {
  background-color: var(--white-color);
  border-radius: var(--border-radius);
}

.pcup_card {
  border: none;
}

.pcup_card > div {
  padding-bottom: 0;
}

.pcup_card_empty {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: calc((1430px / 3)) !important;
  height: 20rem;
  padding: 2rem;
  border-radius: var(--border-radius);
  border: 0.1rem solid var(--grey350-color);
  background-color: #f1f1f1;
}

.pcup_card_empty_text {
  text-align: center;
  padding-bottom: 1rem;
}

/*------ ContentProgressLegend ------*/
.legenda_wrapper {
  position: absolute;
  right: 0rem;
  top: -13rem;
  border: none;
}

.legenda_item_bullet {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: var(--border-radius);
  margin-right: 1rem;
}

/*------ HeartIcon ------*/
.heart_icon,
.heart_icon_disabled,
.heart_icon_outlined,
.heart_icon_filled {
  padding: 0.2rem;
  font-size: 2.3rem;
}

.heart_icon,
.heart_icon_outlined,
.heart_icon_filled {
  color: gray;
}

.heart_icon_disabled {
  color: var(--grey300-color);
}

.heart_icon_outlined,
.heart_icon_filled {
  cursor: pointer;
}

.heart_icon_outlined:hover {
  color: var(--grey700-color);
}

.pcup_card_wrapper .remove_module {
  display: none;
}

.pcup_card_wrapper:hover .remove_module {
  display: block;
}

.remove_module {
  z-index: 100;
  position: absolute;
  right: -10px;
  margin-top: -10px;
  font-size: 21px;
  cursor: pointer;
}
