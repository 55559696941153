.PlaybookPicker {
  background: white;
  border: 1px solid var(--grey350-color);
  padding: 2px 4px;
  border-radius: 6px;
  transition: border 200ms ease;
  cursor: pointer;
  min-height: 32px;
  display: flex;
}

.PlaybookPicker_InnerContainer {
  white-space: nowrap;
  overflow-x: hidden;
}

.PlaybookPicker:hover {
  border-color: var(--primary-color);
}

.PlaybookPicker span {
  border-radius: 6px;
  background: var(--grey200-color);
  padding: 2px 8px;
  display: inline-block;
  margin-right: 10px;
  white-space: nowrap;
}

.PlaybookPicker p {
  margin: 2px 0 0 6px;
  padding: 0;
  color: var(--grey400-color);
}

.PlaybookPicker_OverflowLabel {
  position: relative;
}
.PlaybookPicker_OverflowLabel:before {
  content: "";
  position: absolute;
  left: -60px;
  height: 100%;
  width: 60px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}
.PlaybookPicker_OverflowLabel span {
  margin: 0;
}
