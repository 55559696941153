.textDraft {
  color: black;
}

.textPublished {
  color: var(--primary-color);
}

.link:hover .textDraft {
  text-decoration: underline;
  text-decoration-color: black;
}

.link:hover .textPublished {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
}
