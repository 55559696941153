.grid_default,
.grid_icon_container {
  display: grid;
  gap: 2rem;
}

.grid_icon_container {
  align-items: center;
  grid-template-columns: 3rem auto;
}

/*------- LearningPathDetailOverviewScreen -------*/
.divider {
  position: absolute;
  height: 100%;
  margin: 0 1.5rem;
  border-color: var(--grey400-color);
}

/*------- LearningPathDetailContainer -------*/
.learning_container {
  background-color: var(--background-color);
  padding: 4rem 2rem;
  height: 100%;
  min-width: 60rem;
  max-width: 70%;
  margin: 0 auto;
}

/*------- LearningPathIntroComponent -------*/
.intro_card_cover {
  background-color: var(--placeholder800-color);
  aspect-ratio: 10/2;
  object-fit: cover;
  height: 15rem;
  border-radius: 4px 4px 0 0;
  margin-top: 1px;
  margin-right: 1px;
  margin-left: 1px;
  width: 99.8%;
}

.intro_card_tag {
  border-radius: 4px 0 4px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.intro_card_action {
  cursor: default;
}

.intro_card_action:hover {
  color: rgba(0, 0, 0, 0.45);
  cursor: default;
}

/*------- LearningPathDelayComponent -------*/
.timeline_delay_icon {
  color: var(--white-color);
  border-radius: 100%;
  padding: 0.4rem 0.3rem 0.2rem 0.5rem;
}

/*------- PreviewLearningPathContentComponent -------*/
.breadcrumbs {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 20rem !important;
}

/*------- PreviewLearningPathContentMetaComponent -------*/
.collapse {
  background-color: var(--placeholder800-color);
  margin-top: -6px;
}

.collapse_header {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1rem 2rem;
}

.collapse_body {
  padding-left: 4rem;
}

/*------- CreateLearningPathContentForm -------*/
.form {
  flex: 1;
  background-color: var(--white-color);
  border: 1px solid rgb(233, 233, 233);
  border-radius: var(--border-radius);
  min-width: 45rem;
  padding: 0 2rem;
}

.form_title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 2.4rem;
}

/*------- LearningPathTypesComponent -------*/
.radio_button {
  padding: 0;
  margin-right: 2rem;
  margin-top: 2rem;
  height: 20rem;
  border-radius: var(--border-radius) !important;
}

.card {
  width: 24rem;
  height: 19.8rem;
}

.wrapper_title {
  display: flex;
  align-items: baseline;
}

.icon {
  font-size: 1.8rem;
  padding: 1rem;
}

.title {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
