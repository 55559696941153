/*------ GiphyMediaComponent ------*/
.giphy_media {
  object-fit: cover;
  pointer-events: none;
}

/*------ VimeoUploadComponent ------*/
.upload_wrapper_upload {
  padding-top: 2rem;
  margin-bottom: -1rem;
}

.upload_wrapper_vimeo {
  padding-top: 3rem;
  margin-bottom: -2rem;
}

/*------ SearchMediaModal ------*/
.upload_url_wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.upload_preview {
  flex: 1;
  padding-top: 2rem;
  width: 20rem;
}

.upload_preview_empty {
  border-radius: 1rem 2rem;
  margin-top: 2rem;
  padding: 1rem;
  width: 20rem;
  height: 13rem;
  background-color: whitesmoke;
}

.search_modal_wrapper {
  display: flex;
  flex-flow: wrap;
  padding: 2rem;
  height: 40rem;
  overflow: scroll;
  background-color: whitesmoke;
}

.search_modal_data_wrapper {
  width: 30%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.search_modal_data {
  cursor: pointer;
}

.unsplash_modal_empty {
  align-self: center;
  width: 100%;
  background-color: whitesmoke;
}
