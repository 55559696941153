@import "../../../node_modules/antd/dist/antd.less";
@import "./colors.module.css";
@import "antd.less";

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  min-height: 100vh;
  //background-color: var(--primary-color);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  color: var(--grey700-color);
  background-color: var(--background-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------- GLOBAL COMPONENTS -------*/
.App {
  min-height: 100vh;
  background: var(--background-color);
}

.Auth_container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.Auth_wrapper {
  margin: 10rem;
  padding: 4rem 8rem;
  min-width: 35rem;
  max-width: 50rem;
  min-height: 20rem;
  background-color: var(--white-color);
  border-radius: 0.6rem;
}

.Header {
  background-color: var(--white-color);
  border-bottom: 1px solid var(--grey200-color);
  position: fixed;
  z-index: 100;
  width: 100%;
}

.Content_wrapper {
  padding-top: 1rem;
  width: calc(100vw - 200px) !important;
  left: 20rem;
  overflow: hidden;
  position: relative;
}

.Content_wrapper_without_sider {
  margin: 0 auto;
  padding: 3rem 3rem 0 3rem;
  max-width: 1600px;
  overflow: hidden;
  position: relative;
}

.Content {
  margin: 1.6rem 2.4rem;
}

.Empty_wrapper {
  background-color: var(--white-color);
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
}

.Sider {
  position: fixed;
  height: 100vh;
  overflow: auto;
  left: 0;
}

.Footer {
  text-align: center;
}

.PR-Preview {
  background: var(--primary-color);
  color: white;
  font-weight: bold;
}

.Button {
  align-items: center;
  width: 100%;
}

.Panel .ant-collapse-header-text {
  display: block;
  width: 100%;
}

.react-quill .ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.react-quill .ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-quill .ql-container .ql-editor {
  padding-bottom: 22px;
  max-height: 200px;
  overflow: auto;
}

.react-quill .ql-tooltip {
  z-index: 104;
}

#search-container {
  .search-result {
    em {
      font-style: normal;
      padding: 0.2em;
      background-color: #feffe6;
    }
    padding: 1rem;
    border-bottom: 1px solid black;
  }

  .search-card {
    padding: 1rem;
    background-color: #e5e5e5;
    margin-bottom: 1rem;
  }
}

@primary-color: #1890ff;@border-radius-base: 6px;