.contentMenuContainer {
  pointer-events: all;
  background-color: white;
  color: black;
  border-radius: 12px;
  padding: 8px;
  box-shadow:
    0 4px 10px rgba(0, 0, 0, 0.2),
    0 2px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  transition: all 0.25s ease;
}

.clearSelection {
  cursor: pointer;
  padding: 0.7rem 1rem;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  transition: all 0.15s ease-in-out;

  &:hover {
    border-color: var(--primary-color);
  }
}

.action {
  cursor: pointer;
  padding: 0.7rem 1rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: #f2f2f2;
  }
}
