.container {
  flex: 1;
  background: var(--white-color);
  padding: 24px;
  border-radius: var(--border-radius);
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 6px;
}

.container_spinner {
  display: flex;
  justify-content: center;
  align-content: center;
}

.container_spinner_item {
  align-self: center;
}

/*------- GradientProgressBarComponent -------*/
.boxShadow_red:hover > div > div {
  -moz-box-shadow: 0 0.15rem 0.45rem rgba(var(--notStarted), 0.5);
  -o-box-shadow: 0 0.15rem 0.45rem rgba(var(--notStarted), 0.5);
  -webkit-box-shadow: 0 0.15rem 0.45rem rgba(var(--notStarted), 0.5);
  box-shadow: 0 0.15rem 0.45rem rgba(var(--notStarted), 0.5);
}

.boxShadow_yellow:hover > div > div {
  -moz-box-shadow: 0 0.15rem 0.45rem rgba(var(--started), 0.5);
  -o-box-shadow: 0 0.15rem 0.45rem rgba(var(--started), 0.5);
  -webkit-box-shadow: 0 0.15rem 0.45rem rgba(var(--started), 0.5);
  box-shadow: 0 0.15rem 0.45rem rgba(var(--started), 0.5);
}

.boxShadow_green:hover > div > div {
  -moz-box-shadow: 0 0.15rem 0.45rem rgba(var(--completed), 0.5);
  -o-box-shadow: 0 0.15rem 0.45rem rgba(var(--completed), 0.5);
  -webkit-box-shadow: 0 0.15rem 0.45rem rgba(var(--completed), 0.5);
  box-shadow: 0 0.15rem 0.45rem rgba(var(--completed), 0.5);
}

.boxShadow_pink:hover > div > div > div {
  -moz-box-shadow: 0 0.15rem 0.45rem rgba(var(--pink), 0.5);
  -o-box-shadow: 0 0.15rem 0.45rem rgba(var(--pink), 0.5);
  -webkit-box-shadow: 0 0.15rem 0.45rem rgba(var(--pink), 0.5);
  box-shadow: 0 0.15rem 0.45rem rgba(var(--pink), 0.5);
  margin: 0.1rem;
}
