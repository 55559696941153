.CommandBarModal_Background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.CommandBarModal {
  position: fixed;
  background: white;
  display: flex;
  flex-direction: column;
  top: 50px;
  left: calc(50% - 300px);
  height: 400px;
  width: 600px;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.CommandBarModal_SearchBar {
  font-size: 24px;
}

.CommandBarModal_CommandList_Container {
  overflow: auto;
  width: 100%;
}

.CommandBarModal_CommandList_Item {
  line-height: normal;
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 10px 16px;
}

.CommandBarModal_CommandList_Flexbox {
  display: flex;
  flex: 1;
  align-items: center;
}

.Title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Tag {
  text-transform: capitalize;
  font-size: 12px;
  color: var(--grey500-color);
}

.ReturnIcon {
  background: var(--grey700-color);
  padding: 10px 12px 10px 8px;
  border-radius: 6px;
}

.CloseLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
