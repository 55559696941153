/*------ CreateCardBaseForm ------*/
.form_wrapper_horizontal {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.form_wrapper_bottom {
  padding-bottom: 2rem;
}

.form_alert {
  margin-bottom: 2rem;
}

.form_alert_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 6px;
  padding: 1rem;
}

.character_counter {
  position: absolute;
  right: 10px;
  bottom: 1px;
  padding: 0.2rem;
  background-color: var(--white-color);
  z-index: 103;
}

.form_divider_wrapper {
  cursor: pointer;
}

.form_divider_wrapper:hover .form_divider_button {
  opacity: 1;
}

.form_divider_button {
  opacity: 0.2;
}

.save_button_wrapper {
  position: relative;
}

.save_button_dropdown {
  position: absolute;
  top: -8em;
  right: 0;
  width: 20em;
  background-color: white;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 1em;
  border-radius: 6px;
  z-index: 1050;
  background-clip: padding-box;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.save_button_dropdown_triangle {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -2.4em;
  right: 0;
  z-index: 1000;
}

.save_button {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.info_icon {
  padding: 0 2rem;
}

/*------ CreateOptionForm ------*/
.options_content_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.options_content_item {
  flex: 1;
  padding-left: 1.5rem;
}

.options_explanation_item {
  padding-left: 8rem;
}
