.attribute_manager {
  width: 250px;
}

.attribute_manager ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.attribute_manager li {
  padding: 4px 8px;
}

.attribute_manager li:hover {
  background: var(--grey100-color);
}
.attribute_manager li:hover .action {
  opacity: 1;
}
.attribute_manager .action {
  opacity: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-left: 4px;
  transition:
    opacity 0.1s ease-in-out,
    background 0.2s ease-in-out;
}
.attribute_manager .action:hover {
  background: var(--grey150-color);
}
