/*----- BulkPublishTree -----*/
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_wrapper_content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
