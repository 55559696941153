.qr_code_container {
  display: flex;
  align-items: flex-end;
}

.qr_code_wrapper {
  display: flex;
  flex-flow: column;
  width: 20rem;
}
