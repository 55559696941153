.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 250ms ease;
  user-select: none;
  cursor: pointer;
  margin-right: -24px;
}

.innerContainer {
  display: flex;
  align-items: center;
}

.container p {
  line-height: normal;
  text-align: right;
  margin: 0;
}

.name {
  font-weight: bold;
  padding-bottom: 2px;
}

.role {
  font-size: 12px;
  color: var(--grey500-color);
}
