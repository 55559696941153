.option_text {
  color: var(--disabled-color);
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 1rem;
}

.avatar {
  border: 0.1rem solid var(--white-color);
}

.avatar:hover {
  border: 0.1rem solid var(--disabled-color);
  -moz-box-shadow: 0 0.15rem 0.45rem rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0.15rem 0.45rem rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0.15rem 0.45rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.15rem 0.45rem rgba(0, 0, 0, 0.2);
}
