.container {
  display: flex;
  flex-direction: row;
}

.item {
  display: flex;
  flex: 1;
  margin-left: 16px;
}

.item:first-child {
  margin-left: 0;
}

.header {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
