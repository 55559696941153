.container {
  background: var(--grey200-color);
  border-radius: 6px;
  display: flex;
  color: white;
  padding: 1em;
  flex-direction: row;
  border: 2px solid var(--grey300-color);
  transition: all 150ms;
}

.container:hover {
  color: white;
  border-color: var(--primary-color);
}

.relation {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
}

.label {
  display: block;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}
