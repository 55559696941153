.content_header_content_container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.content_header_card_container {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
}

.content_container,
.content_container_no_hover {
  position: relative;
  background-color: var(--white-color);
  border-radius: var(--border-radius);
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.content_container:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  transition: border 0.3s;
}

.content_container:hover:after {
  border: 1px solid var(--hover-card-border-color);
}

.content_container_selected {
  composes: content_container;
}

.content_container_selected:after {
  border: 1px solid var(--hover-card-border-color);
}

.content_container_selected .content_action {
  opacity: 1;
}

.content {
  cursor: default;
  border: none;
  flex: 1;
}

.content_container:hover .content_action {
  opacity: 1;
}

.content_title {
  display: flex;
  align-items: baseline;
}

.content_action {
  opacity: 0;
  transition: opacity 0.3s;
}

.content_actions_container {
  position: absolute;
  padding-right: 8rem;
  top: 1.9rem;
  right: 0;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  pointer-events: none;
}

/*------- ContentSidebarItemComponent -------*/
.card_type_icon {
  padding: 0.7rem;
  border-radius: 100%;
  color: var(--white-color);
  background-color: var(--card-type-color);
}

.card_type_icon:hover {
  color: var(--white-color);
  background-color: var(--card-type-color);
}

.card_summary_icon {
  padding: 0.7rem;
  border-radius: 100%;
  color: var(--white-color);
  background-color: var(--card-type-color);
}

/*------- CardComponent -------*/
.content_container:hover .card_type_icon,
.content_container:hover .card_summary_icon {
  background-color: var(--hover-card-type-color);
}

/*------- CardTypesComponent -------*/
.card_types_dashed_btn_large {
  color: var(--grey700-color);
  border-color: var(--grey700-color);
  background-color: var(--grey100-color);
  width: 100%;
  padding: 2rem 2rem 4rem;
  text-align: left;
}

.card_types_wrapper {
  display: flex;
  flex-flow: row wrap;
  width: 48rem;
  padding: 2rem 0 1rem 0;
}

.card_types_row {
  flex-flow: row nowrap;
  padding: 1rem 3rem 1rem 1rem;
  margin: 1rem 2rem 1rem 0;
  width: fit-content;
  height: 5rem;
  border-radius: var(--border-radius);
  background-color: var(--placeholder100-color);
  pointer-events: auto;
  cursor: pointer;
}

.card_types_row .card_type_icon {
  background-color: var(--disabled-color);
}

.card_types_row:hover .card_type_icon {
  background-color: var(--card-type-color);
}

.card_types_row:hover {
  background-color: var(--hover-card-type-background-color);
}
