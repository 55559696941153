.action_delete,
.action {
  position: relative;
  cursor: pointer;
  margin-left: 1rem;
}

.action_delete:hover:before,
.action:hover:before {
  opacity: 1;
  transform: scale(1.5);
}

.action:active:before {
  background: var(--blue200-color);
}

.action_delete:before,
.action:before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--blue100-color);
  top: 0;
  border-radius: 100%;
  opacity: 0;
  transform: scale(0);
  transition:
    opacity 0.3s,
    transform 0.3s,
    background-color 0.15s;
}

.action_delete:before {
  background: var(--secondary100-color);
}

.action_delete:hover .label,
.action:hover .label {
  transform: translate(-50%, 0%) rotate(0deg);
  opacity: 1;
}

.label {
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
  transition:
    opacity 0.3s,
    transform 0.3s;
  opacity: 0;
  pointer-events: none;
  color: var(--primary-color);
  user-select: none;
  word-break: initial;
}

.parent_delete,
.parent {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 2rem;
}

.parent:hover {
  color: var(--primary-color);
}

.parent_delete:hover {
  color: var(--secondary800-color);
}
