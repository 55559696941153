/*------- LOGIN FORM COMPONENT -------*/
.Checkbox.ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
  border-radius: 2px;
}

.Space_between.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}

/*------- PLAYBOOK PREVIEW COMPONENT -------*/
.Upload.ant-upload-picture-card-wrapper {
  display: inline;
}

.Upload.ant-upload-picture-card-wrapper
  > .ant-upload-list-picture-card
  .ant-upload-select-picture-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem 2rem;
  opacity: 0;
}

.Upload.ant-upload-picture-card-wrapper
  > .ant-upload-list-picture-card
  .ant-upload-list-picture-card-container {
  display: none;
}

/*------- CONTENT-SIDEBAR COMPONENT -------*/
.Panel.ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}

.Panel.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.Panel_2.ant-collapse-item > .ant-collapse-header,
.ant-collapse
  > .Panel_2.ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding: 0;
}

/*------- CARD COMPONENT -------*/
.Flex_end.ant-form-item
  > .ant-form-item-control:first-child:not([class^="ant-col-"]):not(
    [class*=" ant-col-"]
  ) {
  display: flex;
  align-items: flex-end;
}

.Form_item_card
  > .ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  width: 33rem;
}

/*------- PAGE LAYOUT DETAIL COMPONENT -------*/
.Breadcrumb_detail.ant-breadcrumb > span:last-child a {
  color: #8c8c8c; /*grey[7]*/
}

.Breadcrumb_detail.ant-breadcrumb:hover > span:last-child a {
  color: var(--primary-color);
}

/*------- CONTENT CASCADER COMPONENT -------*/
.Cascader.ant-cascader-menus-placement-bottomLeft > div > .ant-cascader-menu {
  min-width: 16rem;
  max-width: 18rem;
}

/*-------- DUPLICATE MODAL ---------*/
.Modal_Duplicate_Content.ant-modal > .ant-modal-content > .ant-modal-header {
  background-color: var(--primary-color);
}

.Modal_Duplicate_Content.ant-modal .ant-modal-title,
.Modal_Duplicate_Content.ant-modal > .ant-modal-content > .ant-modal-close {
  color: var(--white-color);
}

.Modal_Duplicate_Content-Description {
  padding: 24px 24px 12px;
  border-bottom: 1px solid var(--grey100-color);
}

.Duplicate_Tree .ant-tree-treenode {
  margin-bottom: 4px;
  border-radius: var(--border-radius);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Duplicate_Tree .ant-tree-node-content-wrapper {
  background: transparent;
  padding: 8px 10px 8px 36px;
  margin-left: -24px;
  z-index: 0;
}

.Duplicate_Tree .ant-tree-treenode:hover .ant-tree-node-content-wrapper,
.Duplicate_Tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper {
  background-color: var(--blue100-color);
}

.Duplicate_Tree .ant-tree-switcher {
  z-index: 1;
}

.Duplicate_Tree .ant-tree-switcher .ant-tree-switcher-icon {
  line-height: normal !important;
  padding-top: 12px;
}

.Duplicate_Tree .ant-tree-checkbox {
  margin: 0 -10px 0 0;
  padding: 10px 8px;
  z-index: 1;
}

.Duplicate_Tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox-checked::after {
  border-color: transparent;
}

/*------- BULK PUBLISH MODAL -------*/
.Modal_bulk_publish.ant-modal > .ant-modal-content > .ant-modal-header {
  background-color: var(--primary-color);
}

.Modal_bulk_publish.ant-modal
  > .ant-modal-content
  > .ant-modal-header
  > .ant-modal-title,
.Modal_bulk_publish.ant-modal > .ant-modal-content > .ant-modal-close {
  color: var(--white-color);
}

.Publish_tree.ant-tree > .ant-tree-list > .ant-tree-list-holder > div {
  max-height: 38.3rem;
  overflow: scroll;
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode {
  width: 100%;
  margin-bottom: 0.3rem;
  border-radius: var(--border-radius);
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-switcher {
  line-height: 32px;
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox {
  margin: 0;
  padding: 10px 8px;
  z-index: 1;
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox-checked::after {
  border-color: transparent;
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-node-content-wrapper.ant-tree-node-selected,
.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-node-content-wrapper {
  width: 100%;
  padding: 0.6rem 0 0.8rem 4.2rem;
  margin-left: -32px;
  border-radius: var(--border-radius);
  z-index: 0;
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-node-content-wrapper:hover,
.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox-checked
  + .ant-tree-node-content-wrapper,
.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox-disabled
  + .ant-tree-node-content-wrapper,
.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode.ant-tree-treenode-disabled
  > .ant-tree-checkbox-disabled
  + .ant-tree-node-content-wrapper {
  background-color: var(--blue100-color);
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox-disabled
  + .ant-tree-node-content-wrapper,
.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode.ant-tree-treenode-disabled
  > .ant-tree-checkbox-disabled
  + .ant-tree-node-content-wrapper {
  opacity: 0.5;
}

.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode
  > .ant-tree-checkbox-disabled
  .ant-tree-checkbox-inner::after,
.Publish_tree.ant-tree
  > .ant-tree-list
  > .ant-tree-list-holder
  > div
  > .ant-tree-list-holder-inner
  > .ant-tree-treenode.ant-tree-treenode-disabled
  > .ant-tree-checkbox-disabled
  .ant-tree-checkbox-inner::after {
  /* WN: applied styling (when checkbox is disabled, also show checkmark) */
  position: absolute;
  display: table;
  border: 2px solid var(--grey350-color);
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

/*------- EXCLUSIVE CONTENT MODAL -------*/
.Modal_exclusive.ant-modal
  > .ant-modal-content
  > .ant-modal-header
  > .ant-modal-title,
.Modal_exclusive.ant-modal > .ant-modal-content > .ant-modal-close {
  color: var(--grey800-color);
}

.Modal_exclusive.ant-modal > .ant-modal-content > .ant-modal-header {
  background-color: var(--exclusive-color-primary);
}

.Checkbox_exclusive.ant-checkbox-wrapper-checked,
.Checkbox_exclusive.ant-checkbox-wrapper {
  border-radius: var(--border-radius);
  padding: 1.5rem 2rem;
  margin-left: 2rem;
  margin-bottom: 0.7rem;
}

.Checkbox_exclusive.ant-checkbox-wrapper
  > .ant-checkbox-checked
  > .ant-checkbox-inner {
  background-color: var(--exclusive-color-primary);
  border-color: var(--exclusive-color-primary);
}

.Checkbox_exclusive.ant-checkbox-wrapper > span:nth-child(2) {
  padding-left: 2rem;
}

.Checkbox_exclusive:hover,
.Checkbox_exclusive.ant-checkbox-wrapper-checked {
  background-color: var(--exclusive-color-light);
  border-radius: var(--border-radius);
}

/*------- LearningPathTypesComponent -------*/
.RadioGroup {
  display: flex;
}

.RadioGroup > label.ant-radio-button-wrapper {
  border-left-width: 1px;
}

.RadioGroup > label.ant-radio-button-wrapper::before {
  display: none;
}

.RadioGroup
  > label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled
  > span
  > .ant-card {
  background-color: var(--placeholder100-color);
  border-color: var(--placeholder100-color);
}

.RadioGroup
  > label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled
  > span
  > .ant-card
  > .ant-card-body
  > span,
.RadioGroup
  > label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled
  > span
  > .ant-card
  > .ant-card-body
  > div
  > span {
  color: var(--disabled-color);
}

/*------- LEARNING PATH DETAIL OVERVIEW SCREEN -------*/
.Timeline > .ant-timeline-item {
  padding-bottom: 4rem;
}

.Timeline > .ant-timeline-item > .ant-timeline-item-tail {
  border-left: 2px dashed var(--disabled-color);
  top: 0;
}

.Timeline > .ant-timeline-item > .ant-timeline-item-head-custom {
  background-color: var(--background-color);
}

.Timeline > .ant-timeline-item > .ant-timeline-item-content {
  display: flex;
  flex-flow: column;
  margin: 0 0 0 4rem;
  top: -1rem;
}

/*------- ModuleDetailContainer -------*/
.Module_detail > div.ant-collapse-item > div.ant-collapse-header,
.Module_detail
  > div.ant-collapse-item
  > div.ant-collapse-content
  > div.ant-collapse-content-box {
  padding: 0 !important;
}

.Module_detail > div.ant-collapse-item > div.ant-collapse-content {
  margin: 0 0 2rem 5rem !important; // Container for collections
}

.custom-fixed-header,
.custom-fixed-header .ant-spin-nested-loading,
.custom-fixed-header .ant-spin-container,
.custom-fixed-header .ant-table,
.custom-fixed-header .ant-table-container {
  height: 100%;
}

.custom-fixed-header .ant-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.custom-fixed-header .ant-table-body {
  display: flex;
  flex: 1;
  max-height: 100% !important;
  flex-direction: column;
}

.custom-fixed-header .ant-table,
.custom-fixed-header .ant-table table,
.custom-fixed-header .ant-table-container {
  border-radius: 0;
}

.custom-fixed-header .ant-table table tbody tr {
  max-height: 42px;
}

/*------- UserGroupLearningPathReportTable -------*/
.user-group-learning-path-report-table {
  th,
  td {
    padding: 0.5em 1em !important;
  }
}

.ant-drawer-content-wrapper {
  margin: 12px;
  border-radius: 12px;
  overflow: hidden;
}
