.option {
  display: flex;
  align-items: center;
  cursor: default;
  height: 130px;
}

.handle {
  cursor: grab;
  opacity: 0;
  transition: opacity 150ms ease;
  padding-right: 5px;
}

.handle span {
  font-size: 18px;
}

.handle span:nth-child(2) {
  margin-left: -8px;
}

.deleteContainer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 150ms ease;
  background: rgb(0, 0, 0);
  background: -moz-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

.deleteContainer div {
  cursor: pointer;
}

.imageContainer {
  position: relative;
}

.option:hover .handle,
.imageContainer:hover .deleteContainer {
  opacity: 1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  border-radius: 1000px;
  overflow: hidden;
  border: 1px solid var(--grey200-color);
}

.switch span div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0.6);
  border-radius: 1000px;
  overflow: hidden;
  background: transparent;
}

.switch:hover span {
  border: 1px solid var(--primary-color);
}

.switch input:checked + span {
  border: 1px solid var(--primary-color);
}

.switch input:checked + span div {
  background: var(--primary-color);
}
