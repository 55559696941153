/*------- ContentSidebarComponent -------*/
.container {
  background-color: var(--grey700-color);
  display: flex;
  max-width: 550px;
  height: 93vh;
}

.container_column {
  height: 100%;
  width: 275px;
  padding-bottom: 2rem;
  overflow: scroll;
  scrollbar-width: none;
}

.container_column .container_header {
  background-color: var(--grey800-color);
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.container_column::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.container_column:first-child {
  background-color: var(--grey800-color);
  opacity: 1;
  overflow-x: hidden;
}

.container_column:last-child {
  width: 275px;
}

.parent_sidebar {
  padding-bottom: 1rem;
}

.child_sidebar {
  position: relative;
  padding: 1rem 0 1rem 2rem;
}

.child_sidebar:before {
  content: "";
  position: absolute;
  background-color: var(--grey500-color);
  width: 3px;
  top: 1rem;
  left: 0.5rem;
  bottom: 1rem;
  border-radius: 999px;
}

/*------- ContentSidebarItemButtonComponent -------*/
.button_sidebar_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_sidebar_wrapper div:first-of-type {
  text-overflow: ellipsis;
  overflow: hidden;
}

.button_sidebar_wrapper div:last-child {
  padding-left: 0.5rem;
}

.button_sidebar {
  position: relative;
  padding: 0.8rem;
  border-radius: var(--border-radius);
}

.button_sidebar div div:last-child,
.button_sidebar_wrapper div:last-child {
  opacity: 0;
}

.button_sidebar:hover div div:last-child,
.button_sidebar_wrapper:hover div:last-child {
  opacity: 1;
}

.button_sidebar_button_triangle {
  position: absolute;
  top: 9px;
  font-size: 2.2rem;
  padding: 0;
  z-index: 101;
}

.container_column:first-child .button_sidebar_button_triangle {
  right: -1.85rem;
  color: var(--grey700-color);
}

.container_column:last-child .button_sidebar_button_triangle {
  right: -2.65rem;
  color: var(--background-color);
}

.button_sidebar_create_button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*------- ContentSidebarComponent Resize Sidebars -------*/
.app_sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 20px;
  max-width: 275px;
  display: flex;
  flex-direction: row;
  border-radius: 10px 0 0 10px;
}

.app_sidebar .app_sidebar_resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0.8rem;
  justify-self: flex-end;
  cursor: ew-resize;
  resize: horizontal;
  border-left: var(--grey700-color) 1px solid;
}

.app_sidebar .app_sidebar_resizer:hover {
  width: 0.2rem;
  cursor: ew-resize;
  border-left: 1px solid var(--primary-color);
}
