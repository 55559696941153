.playbook_dropdown_danger {
  color: var(--secondary800-color);
}

.playbook_dropdown_danger:hover {
  background-color: var(--secondary100-color);
}

.playbook_card {
  border-radius: 1rem 2rem;
  padding: 0;
  margin: 0;
}

.playbook_card_wrapper {
  border-radius: 6px;
  background: var(--white-color);
  box-shadow: 1.64706px 2.47059px 7.41176px var(--grey400-color);
  overflow: hidden;
  width: 26rem;
  aspect-ratio: 16/9;
}

.playbook_card_image {
  height: 100%;
  overflow: hidden;
  background-color: var(--primary-color);
}
.playbook_card_image .ant-image {
  height: 100%;
  width: 100%;
}

.playbook_card_meta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  padding: 50px 10px 10px;
  border-radius: 6px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
