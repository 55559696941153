.braft_editor {
  border: 0.1rem solid var(--grey350-color);
  border-radius: 4px;
  height: 20rem;
  width: 80%;
}

.list {
  width: 60%;
  border: 1px solid var(--background-color);
}

.skeleton_character_counter {
  width: 6rem;
  margin-top: -1rem;
}

/*------- Editable Text Component -------*/
.editable_wrapper div {
  display: inline-block;
}

.editable_title {
  display: inline-block;
  padding-right: 1rem;
}

.btn_edit_header_text {
  color: var(--primary-color);
  border: 1px solid var(--grey350-color) !important;
  margin-left: 1rem;
}

.btn_edit_header_text,
.btn_toggle {
  display: inline-block;
  background: transparent;
  border: 0;
}
