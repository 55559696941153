.tag {
  border-radius: 6px;
  padding: 1px 8px;
  background: #f0f0f0;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 3px;
  font-size: 1.2rem;
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  user-select: none;
}

.tag .delete {
  cursor: pointer;
  margin-left: 0.5rem;
}

.tag .delete:hover {
  opacity: 0.5;
}
