.grid_default,
.grid_default_row {
  display: grid;
}

.grid_default {
  grid-auto-flow: column;
  column-gap: 1.6rem;
}

.grid_default_row {
  row-gap: 1.6rem;
}

/*------- UserDetailOverviewScreen -------*/
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 5rem;
  overflow: auto hidden;
}

/*------- ModuleCompletionComponent -------*/
.completion_card {
  border: none;
  margin-bottom: 1.6rem;
}

.completion_wrapper {
  padding: 1.2rem 0;
  margin-left: 4rem;
}

.completion_divider {
  display: block;
  height: 1.2rem;
  margin: 0 0.5rem;
  border-left: 0.3rem solid var(--grey200-color);
}

/*------- UserContentCompletionDetailsComponent -------*/
.completion_details_container {
  padding: 3rem;
  border-radius: var(--border-radius);
  background-color: #eaeaea;
  max-width: 90rem;
}

.completion_details_wrapper {
  margin-bottom: 5.6rem;
}

/*------- CollectionStatisticsComponent -------*/
.overview_wrapper {
  flex-wrap: nowrap;
  overflow: auto;
}

/*------- CardOverviewComponent -------*/
.card_overview_wrapper {
  min-width: 54.2rem;
  padding-bottom: 2.4rem;
}
