/*----- ContentContextMenu -----*/
.context_menu {
  color: var(--black-color);
}

.context_menu_danger {
  color: var(--secondary800-color);
}

.context_menu_danger:hover {
  background-color: var(--secondary100-color);
}

.context_menu_publish {
  color: var(--primary-color);
}

.context_menu_publish:hover {
  background-color: var(--blue100-color);
}

/*----- PlaybookOverviewScreen -----*/
.context_header {
  display: flex;
  align-items: center;
  height: 3rem;
}

/*----- ContentContextCheckbox -----*/
.context_checkbox_wrapper {
  display: flex;
  justify-content: flex-start;
  padding-left: 3rem;
  width: 100%;
}

.context_checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  margin-right: 2rem;
}

.context_checkbox:hover:before {
  opacity: 1;
  transform: scale(1.5);
}

.context_checkbox:before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--white-color);
  border-radius: 100%;
  opacity: 0;
  transform: scale(0);
  transition:
    opacity 0.3s,
    transform 0.3s,
    background-color 0.15s;
}

.context_checkbox_normal,
.context_checkbox_danger {
  position: relative;
  font-size: 2rem;
}

.context_checkbox_normal:hover {
  color: var(--primary-color);
}

.context_checkbox_danger:hover {
  color: var(--secondary800-color);
}
