/* ----------------------------------------------
 * Generated by Animista on 2022-7-26 11:52:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
.vibrate {
  -webkit-animation: vibrate-1 5s linear infinite both;
  animation: vibrate-1 5s linear infinite both;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
.animation_wobble {
  display: block;
  margin: 0 auto;
}

.animation_wobble[wobble="1"] {
  animation: wobble-hor-bottom 0.8s both;
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-1px) rotate(-6deg);
    transform: translateX(-1px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(1px) rotate(6deg);
    transform: translateX(1px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-1px) rotate(-3.6deg);
    transform: translateX(-1px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(1px) rotate(2.4deg);
    transform: translateX(1px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(0px) rotate(-1.2deg);
    transform: translateX(0px) rotate(-1.2deg);
  }
}
